import forEach from 'lodash/forEach'

const loading = () => {
    if (window.requestIdleCallback) {
        window.requestIdleCallback(() => loadApp())
    } else {
        loadApp()
    }
}

const getScript = (source, callback) => {
    let script = document.createElement('script')
    let prior = document.getElementsByTagName('script')[0]

    script.async = 1

    script.onload = script.onreadystatechange = (_, isAbort) => {
        if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
            script.onload = script.onreadystatechange = null
            script = undefined

            if (!isAbort) if (callback) callback()
        }
    }

    script.src = source
    prior.parentNode.insertBefore(script, prior)
}

const loadApp = () => {
    let stylesheets = document.querySelectorAll('.app-stylesheet')
    let mainScript = window.assetsObject ? window.assetsObject.script : false
    // let orejime = '//unpkg.com/orejime@1.0.3/dist/orejime.js'
    let browserOutdated = '//browser-update.org/update.min.js'

    if (stylesheets && stylesheets.length) {
        forEach(
            stylesheets,
            stylesheet => {
                stylesheet.removeAttribute('disabled')
            }
        )
    }

    if (mainScript) {
        getScript(
            mainScript,
            () => {
                /*if (orejime) {
                    getScript(orejime)
                }*/

                if (browserOutdated) {
                    getScript(browserOutdated)
                }
            }
        )
    }
}

/**
 * DOM Loaded
 */
if (window.addEventListener) {
    window.addEventListener('load', loading, false)
} else if (window.attachEvent) {
    window.attachEvent('onload', loading)
} else {
    window.onload = loading
}

/** Create jQuery ref */
window.$ = window.jQuery
